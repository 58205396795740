import { addClass, removeClass } from "./dom";
import { Decimal } from "decimal.js";

export const TransitionHook = {
  beforeEnter(el: Element) {
    addClass(el as HTMLElement, "collapse-transition");
    (el as HTMLElement).dataset.oldPaddingTop = (
      el as HTMLElement
    ).style.paddingTop;
    (el as HTMLElement).dataset.oldPaddingBottom = (
      el as HTMLElement
    ).style.paddingBottom;

    (el as HTMLElement).style.height = "0px";
    (el as HTMLElement).style.paddingTop = "0px";
    (el as HTMLElement).style.paddingBottom = "0px";
  },
  enter(el: Element) {
    (el as HTMLElement).dataset.oldOverflow = (
      el as HTMLElement
    ).style.overflow;
    if ((el as HTMLElement).scrollHeight !== 0) {
      (el as HTMLElement).style.height =
        (el as HTMLElement).scrollHeight + "px";
      (el as HTMLElement).style.paddingTop = (
        el as HTMLElement
      ).dataset.oldPaddingTop!;
      (el as HTMLElement).style.paddingBottom = (
        el as HTMLElement
      ).dataset.oldPaddingBottom!;
    } else {
      (el as HTMLElement).style.height = "";
      (el as HTMLElement).style.paddingTop = (
        el as HTMLElement
      ).dataset.oldPaddingTop!;
      (el as HTMLElement).style.paddingBottom = (
        el as HTMLElement
      ).dataset.oldPaddingBottom!;
    }
    (el as HTMLElement).style.overflow = "hidden";
  },
  afterEnter(el: Element) {
    // for safari: remove class then reset height is necessary
    removeClass(el as HTMLElement, "collapse-transition");
    (el as HTMLElement).style.height = "";
    (el as HTMLElement).style.overflow = (
      el as HTMLElement
    ).dataset.oldOverflow!;
  },
  beforeLeave(el: Element) {
    (el as HTMLElement).dataset.oldPaddingTop = (
      el as HTMLElement
    ).style.paddingTop;
    (el as HTMLElement).dataset.oldPaddingBottom = (
      el as HTMLElement
    ).style.paddingBottom;
    (el as HTMLElement).dataset.oldOverflow = (
      el as HTMLElement
    ).style.overflow;
    (el as HTMLElement).style.height = (el as HTMLElement).scrollHeight + "px";
    (el as HTMLElement).style.overflow = "hidden";
  },
  leave(el: Element) {
    if (el.scrollHeight !== 0) {
      // for safari: add class after set height, or it will jump to zero height suddenly, weired
      addClass(el as HTMLElement, "collapse-transition");
      (el as HTMLElement).style.height = "0px";
      (el as HTMLElement).style.paddingTop = "0px";
      (el as HTMLElement).style.paddingBottom = "0px";
    }
  },
  afterLeave(el: Element) {
    removeClass(el as HTMLElement, "collapse-transition");
    (el as HTMLElement).style.height = "";
    (el as HTMLElement).style.overflow = (
      el as HTMLElement
    ).dataset.oldOverflow!;
    (el as HTMLElement).style.paddingTop = (
      el as HTMLElement
    ).dataset.oldPaddingTop!;
    (el as HTMLElement).style.paddingBottom = (
      el as HTMLElement
    ).dataset.oldPaddingBottom!;
  },
};

export const showThousands = (_num: number | string): string => {
  let num = removeEndZero(_num);
  if (num == "-" || num == "--") {
    return num;
  }
  if (!num) {
    return "0";
  }
  let s = num.toString().indexOf(".");
  if (s == -1) {
    return (num || 0).toString().replace(/(\d)(?=(?:\d{3})+$)/g, "$1,");
  } else {
    let arr = num.toString().split(".");
    if (arr.length > 1 && arr[1].length < 2) {
      return (
        (arr[0] || 0).toString().replace(/(\d)(?=(?:\d{3})+$)/g, "$1,") +
        "." +
        arr[1]
      );
    } else {
      return (
        (arr[0] || 0).toString().replace(/(\d)(?=(?:\d{3})+$)/g, "$1,") +
        "." +
        arr[1]
      );
    }
  }
};

export const removeEndZero = (value: number | string): number | string => {
  if (value === "--") {
    return "--";
  }
  if (!value) {
    value = "0";
  }
  return new Decimal(value).toNumber();
};

export const convertToBillion = (value: number | string, fixed: number = 2): number | string => {
  if (value === "--") {
    return "--";
  }
  if (!value) {
    value = "0";
  }
  return removeEndZero(((value as number) / 1000000000).toFixed(fixed));
};

export const convertToMillion = (value: number | string, fixed: number = 2): number | string => {
  if (value === "--") {
    return "--";
  }
  if (!value) {
    value = "0";
  }
  return removeEndZero(((value as number) / 1000000).toFixed(fixed));
};

export const getUrlHost = (url: string) => {
  let _url = new URL(url);
  return _url.hostname;
};

export const positiveFloatFixedReg = (_toFixed: number) => {
  return new RegExp(`\\D*((([1-9]\\d*(\\.\\d{0,${_toFixed}})?|0{1}(\\.\\d{0,${_toFixed}})?))?).*`,"g");
};
